import type { ToastData } from "types/app/toast.types";
import { useEffect } from "react";
import { toast as showToast } from "sonner";

/** Display a toast message on component mount. */
export function useToast(toast?: ToastData | null) {
  useEffect(() => {
    if (toast) {
      setTimeout(() => {
        showToast[toast.type](toast.title, {
          id: toast.id,
          description: toast.description,
        });
      }, 0);
    }
  }, [toast]);
}
