import type { ToasterProps } from "~/components/Toaster/Toaster.types";
import { Toaster as SonnerToaster } from "sonner";

export default function Toaster({ ...props }: ToasterProps) {
  return (
    <SonnerToaster
      className="toaster group"
      position="bottom-right"
      toastOptions={{
        closeButton: true,
      }}
      {...props}
    />
  );
}
